<template>
  <div class="page--pimple-pop finish bbb-pages">
    <template v-if="isVnSource">
      <div class="header">
        <div class="wrapper">
          <a href="" class="close-button" @click.prevent="backHome()"
            ><img src="/static/img/pimple-pop/close-button@2x.png" width="86"
          /></a>
        </div>
      </div>
    </template>
    <div class="bbb-finish">
      <img
        src="/static/img/big-bang-boom/game-over-bbb-store.png"
        class="title"
        :class="{ 'title-vn': isVnSource }"
        width="586"
        height="317"
      />
      <div v-if="isVnSource" class="score" :class="{ 'score-vn': isVnSource }">
        BẠN ĐƯỢC
        <span>{{ zits }}</span>
        ĐIỂM
      </div>
      <div v-else class="score">
        YOU GET
        <span>{{ zits }}</span>
        POINTS
      </div>
      <template v-if="isVnSource">
        <div v-if="voucherCopywriting && allowed" class="box-congrats" :class="{ 'box-congrats-vn': isVnSource }">
          <h2 class="box-congrats__heading vn">CHÚC MỪNG BẠN!</h2>

          <span class="box-congrats__text vn">BẠN NHẬN ĐƯỢC:</span>
          <div class="coupon-vn">
            <span class="coupon__text-reward" v-html="voucherCopywriting"></span>
          </div>

          <a href="" class="button-print-voucher" @contextmenu.prevent @click.prevent="toggleModalVoucher">
            <template v-if="!processing"> NHẬN NGAY! </template>
            <div v-else id="loaderspinwrap" class="loaderspinwrap"><div class="loaderspin"></div></div>
          </a>
        </div>

        <div v-else class="box-congrats failed">
          <h2 class="box-congrats__heading vn">ĐỪNG VỘI BỎ CUỘC BẠN NHÉ VÌ MÃ GIẢM GIÁ VẪN CÒN RẤT NHIỀU!</h2>
          <button class="button-print-voucher" @click="backHome()">QUAY LẠI MÀN HÌNH TRANG CHỦ</button>
        </div>
      </template>
      <template v-else>
        <div v-if="voucherCopywriting && allowed" class="box-congrats">
          <h2 class="box-congrats__heading">CONGRATULATIONS!</h2>

          <span class="box-congrats__text">YOU GET:</span>
          <div class="coupon">
            <span class="coupon__text-reward">{{ voucherCopywriting }}</span>
          </div>

          <a href="" class="button-print-voucher" @click.prevent="openModal">
            <template v-if="!processing"> PRINT VOUCHER </template>
            <div v-else id="loaderspinwrap" class="loaderspinwrap"><div class="loaderspin"></div></div>
          </a>
          <button class="button-print-voucher" @click="backHome()">BACK TO HOME SCREEN</button>
        </div>

        <div v-else class="box-congrats failed">
          <h2 class="box-congrats__heading">Oops! It's not a bad day, just try your luck next time</h2>
          <a href="/vending-machine/home" class="button-print-voucher" @click="postTracker('finish', zits)">
            BACK TO HOME SCREEN
          </a>
        </div>
      </template>
    </div>
    <template v-if="isVnSource">
      <GModal
        v-show="isModalVoucherOpen"
        ref="modalFilterBrand"
        class="modal--full-fixed modal--print-voucher modal--print-voucher-dialog modal-printed"
      >
        <div slot="header"></div>
        <div slot="body" ref="modalBodyFilterBrand" class="content">
          <div class="voucher-printed">
            <img src="/static/img/icons/logo-sociolla.png" width="332" height="81" class="title-vn" />
            <h2 class="reward-vn">GIẢM {{ VoucherPrintCopyWrite }} TỔNG HOÁ ĐƠN</h2>
            <p class="minimum"><sup>*</sup>Tối đa {{ maximumDisc }} đ</p>
            <svg
              v-if="voucherCode"
              id="barcode"
              jsbarcode-format="CODE128"
              :jsbarcode-value="voucherCode"
              jsbarcode-textmargin="0"
              jsbarcode-fontoptions="bold"
              :jsbarcode-text="`MÃ CỦA BẠN: ${voucherCode}`"
              width="260"
            ></svg>
            <p class="greeting-vn">
              Vui lòng chụp màn hình này hoặc kiểm tra hộp thư điện tử <br />
              của bạn để lưu mã và mua sắm tại Sociolla.
            </p>
            <p class="greeting-vn">
              Mã giảm giá có giá trị đến hết ngày <b>{{ today }}</b> và chỉ áp <br />dụng cho khách hàng đã checkin.<br />
            </p>
            <p class="greeting-vn">Mỗi khách hàng chỉ được nhận 1 mã/ngày.</p>
            <button class="btn-back" @click="backHome()">QUAY LẠI TRANG CHỦ</button>
          </div>
        </div>
      </GModal>
    </template>
    <template v-else>
      <GModal
        v-show="isModalPrintOpen"
        ref="modalFilterBrand"
        class="modal--full-fixed modal--print-voucher modal--print-voucher-dialog"
        :class="{ 'modal-printed': stateIsPrinted }"
      >
        <div slot="header"></div>
        <div slot="body" ref="modalBodyFilterBrand" class="content">
          <div v-if="!stateIsPrinted" class="print-voucher">
            <img src="/static/img/pimple-pop/printer@2x.png" width="477" />
            <p class="copywriting">Voucher is printing...</p>
          </div>

          <div v-else class="voucher-printed">
            <img src="/static/img/pimple-pop/voucher-printed@2x-v2.png" width="542" />
            <h3 class="heading">VOUCHER PRINTED!</h3>
            <p class="greeting">Terima kasih sudah menggunakan <br />SOCO Vending Machine</p>

            <a href="/vending-machine/home" class="btn-back" @click="postTracker('finish', zits, voucherCode)">
              BACK TO HOME SCREEN
            </a>
          </div>
        </div>
      </GModal>
    </template>

    <GModal ref="modalFilterBrand" class="modal--full-fixed modal--print-voucher modal-voucher">
      <div slot="header">
        <!-- <a href="" class="modal-dismiss" @click.prevent="toggleModalVoucher"><img src="/static/img/icons/close-modal.svg" /></a> -->
      </div>
      <div slot="body" ref="modalBodyFilterBrand" class="content">
        <div class="voucher-printed">
          <template v-if="isVnSource">
            <img src="/static/img/icons/sociolla.jpg" width="130" />
            <h2 class="reward-vn">GIẢM {{ VoucherPrintCopyWrite }} TỔNG HOÁ ĐƠN</h2>
            <p class="minimum"><sup>*</sup>Tối đa 75.000 đ</p>
            <svg
              v-if="voucherCode"
              id="barcode"
              jsbarcode-format="CODE128"
              :jsbarcode-value="voucherCode"
              jsbarcode-textmargin="0"
              jsbarcode-fontoptions="bold"
              width="260"
            ></svg>
            <p class="greeting">
              Vui lòng chụp màn hình này hoặc kiểm tra hộp thư điện tử của bạn để lưu<br />
              mã và mua sắm tại Sociolla.
            </p>
            <p class="greeting">
              Mã giảm giá có giá trị đến hết ngày {{ today }} và chỉ áp <br />dụng cho khách hàng đã checkin.<br />
            </p>
          </template>
          <template v-else>
            <img src="/static/img/icons/sociolla.jpg" width="130" />
            <h4 class="fullname">
              Hi <span class="bolder">{{ fullname }}</span>
            </h4>
            <p class="copywriting">Enjoy your Press Play voucher</p>
            <h2 class="reward">{{ VoucherPrintCopyWrite }} OFF</h2>
            <p class="minimum"><sup>*</sup>Minimum Purchase Rp100.000</p>
            <svg
              v-if="voucherCode"
              id="barcode"
              jsbarcode-format="CODE128"
              :jsbarcode-value="voucherCode"
              jsbarcode-textmargin="0"
              jsbarcode-fontoptions="bold"
              width="260"
            ></svg>
            <p class="greeting">To redeem, show this voucher to or cashier before making your purchase.</p>
            <p class="greeting">
              This voucher valid only for today<br />({{ today }})
              <template v-if="voucherEmail"> and eligible for {{ voucherEmail }}. </template>
              <template v-else>.</template>
            </p>
          </template>
        </div>
      </div>
    </GModal>
  </div>
</template>

<script>
const moment = require('moment');
const JsBarcode = require('jsbarcode');
import VendingMachineTracker from '@/mixins/vending-machine-tracker';

export default {
  name: 'PimplePopFinish',
  mixins: [VendingMachineTracker],
  data() {
    return {
      isModalVoucherOpen: false,
      isModalPrintOpen: false,
      stateIsPrinted: false,
      copyNotAllowed: "Oops! It's not a bad day, just try your luck next time.",
      processing: false,
      voucherCode: '',
      voucherEmail: '',
      allowed: true,
      store_id: '',
      store_name: '',
      lowerScoreMode: false,
      timeout: 5 * 60 * 1000,
      interval: '',
    };
  },
  computed: {
    fullname() {
      if (this.$SO.size(this.$store.state.auth.userProfile)) {
        return this.$store.state.auth.userProfile.name;
      } else {
        return '';
      }
    },
    today() {
      if (this.$route.query.isvn) {
        return moment().format('DD/MM/YYYY');
      } else {
        return moment().format('Do MMMM YYYY');
      }
    },
    voucherCopywriting() {
      if (this.isVnSource) {
        if (this.zits > (this.lowerScoreMode ? 25 : 80)) {
          return `GIẢM 15% <br/> TỔNG HOÁ ĐƠN`;
        } else if (this.zits >= (this.lowerScoreMode ? 20 : 10) && this.zits <= (this.lowerScoreMode ? 25 : 80)) {
          return `GIẢM 10% <br/> TỔNG HOÁ ĐƠN`;
        } else {
          return false;
        }
      } else {
        if (this.zits >= (this.lowerScoreMode ? 25 : 100)) {
          return `15% OFF STOREWIDE`;
        } else if (this.zits >= (this.lowerScoreMode ? 20 : 50) && this.zits < (this.lowerScoreMode ? 25 : 100)) {
          return `10% OFF STOREWIDE`;
        } else {
          return false;
        }
      }
    },
    VoucherPrintCopyWrite() {
      if (this.isVnSource) {
        if (this.zits > (this.lowerScoreMode ? 25 : 80)) {
          return `15%`;
        } else if (this.zits >= (this.lowerScoreMode ? 20 : 10) && this.zits <= (this.lowerScoreMode ? 25 : 80)) {
          return `10%`;
        } else {
          return '-';
        }
      } else {
        if (this.zits >= (this.lowerScoreMode ? 25 : 100)) {
          return `15%`;
        } else if (this.zits >= (this.lowerScoreMode ? 20 : 50) && this.zits < (this.lowerScoreMode ? 25 : 100)) {
          return `10%`;
        } else {
          return '-';
        }
      }
    },
    zits() {
      return this.$store.state.pimplePop.zits;
    },
    maximumDisc() {
      // if(this.$route.query.isvn){
      // 	return this.VoucherPrintCopyWrite == '15%' ? 75000 : 50000
      // } else {
      // 	return this.VoucherPrintCopyWrite == '15%' ? 150000 : 100000
      // }
      return this.VoucherPrintCopyWrite == '15%' ? 150000 : 100000;
    },
    isVnSource() {
      return this.$route.query.isvn;
    },
  },
  mounted() {
    // JsBarcode("#barcode").init();
  },
  beforeDestroy() {
    this.$store.commit('setZits', 0);
    const parent = document.querySelector('html');
    if (parent != undefined) {
      parent.classList.remove('noscroll');
    }
    clearInterval(this.interval);
  },
  created() {
    this.voucherCode = '';
    this.getVoucher();
    if (document.cookie.indexOf(`pimplePopUser-${this.$store.state.auth.userProfile._id}=1`) > -1) {
      this.allowed = false;
      this.startTimer();
    }
  },
  methods: {
    closeModal() {
      this.isModalPrintOpen = false;
    },
    openModal() {
      this.isModalPrintOpen = true;
      setTimeout(() => {
        window.print();
        this.stateIsPrinted = true;
      }, 2000);
    },
    toggleModalVoucher() {
      this.isModalVoucherOpen = !this.isModalVoucherOpen;
      const parent = document.querySelector('html');
      if (this.isModalVoucherOpen == true) {
        if (parent != undefined) {
          parent.classList.add('noscroll');
        }
      } else {
        if (parent != undefined) {
          parent.classList.remove('noscroll');
        }
      }
    },
    async backHome() {
      try {
        await this.postTracker('finish', this.zits, this.voucherCode);
        if (this.isVnSource) {
          this.$router.push('/vending-machine-vn/home?isvn=true');
        } else {
          const path = (await Cookies.get('vending_machine_device')) == 'true' ? 'home' : 'login';
          this.$router.push('/vending-machine/' + path);
        }
      } catch (err) {
        console.log(err);
        if (this.isVnSource) {
          this.$router.push('/vending-machine-vn/home?isvn=true');
        } else {
          const path = (await Cookies.get('vending_machine_device')) == 'true' ? 'home' : 'login';
          this.$router.push('/vending-machine/' + path);
        }
      }
    },
    limitUser() {
      if (!this.$store.state.auth.userProfile) {
        return false;
      }

      const m = moment();
      // sociolla-admin expects date in utc > 23:59:29(id)== 16:59:59(utc)
      const endDateDay = new Date(m.get('year'), m.get('month'), m.get('date'), 16, 59, 59);
      const cookiename = `pimplePopUser-${this.$store.state.auth.userProfile._id}=1`;

      if (document.cookie.indexOf(cookiename) < 0) {
        document.cookie = `${cookiename}; expires=${endDateDay}`;
      }
    },
    async getVoucher() {
      let value = 15;
      const m = moment();
      if (this.isVnSource) {
        if (this.zits >= (this.lowerScoreMode ? 20 : 10) && this.zits <= (this.lowerScoreMode ? 25 : 80)) {
          value = 10;
        }
        if (this.zits > (this.lowerScoreMode ? 25 : 80)) {
          value = 15;
        }
        if (this.zits < (this.lowerScoreMode ? 20 : 10)) {
          return false;
        }
      } else {
        if (this.zits >= (this.lowerScoreMode ? 20 : 50) && this.zits < (this.lowerScoreMode ? 25 : 100)) {
          value = 10;
        }
        if (this.zits >= (this.lowerScoreMode ? 25 : 100)) {
          value = 15;
        }
        if (this.zits < (this.lowerScoreMode ? 20 : 50)) {
          return false;
        }
      }

      const cookieUser = `pimplePopUser-${this.$store.state.auth.userProfile._id}=1`;
      if (document.cookie.indexOf(cookieUser) > -1) {
        return false;
      }
      if (Cookies.get('store_detail') && Cookies.get('store_detail') != undefined) {
        try {
          const json_data = JSON.parse(Cookies.get('store_detail'));
          this.store_id = json_data._id;
          this.store_name = json_data.name;
          this.store_alias = json_data.alias;
        } catch (err) {}
      }
      const regex = '%';
      let prefixVn = 'BBB';

      if (this.VoucherPrintCopyWrite) {
        const replace = this.VoucherPrintCopyWrite.search(regex);
        if (replace >= 0) {
          prefixVn = 'BBB' + this.VoucherPrintCopyWrite.replace(regex, '');
        }
      }

      if (!this.processing) {
        this.processing = true;
        // sociolla-admin expects date in utc > 23:59:29(id)== 16:59:59(utc)
        const date = this.isVnSource
          ? `${m.add(1, 'days').format('YYYY-MM-DD')} 16:59:59`
          : `${m.format('YYYY-MM-DD')} 16:59:59`;
        let tokenStr = Cookies.get('token');
        if (tokenStr == undefined || !tokenStr) {
          tokenStr = this.$store.state.auth.authToken;
        }

        const payload = {
          voucher_name:
            'Big Bang Boom Voucher ' +
            (this.$store.state.auth.userProfile ? this.$store.state.auth.userProfile.email : ''),
          voucher_description:
            'Big Bang Boom Voucher ' +
            (this.$store.state.auth.userProfile ? this.$store.state.auth.userProfile.email : ''),
          voucher_value: value,
          minimum: this.isVnSource ? 0 : 100000,
          maximum_discount: this.maximumDisc,
          validate_date: date,
          super_voucher: 1,
          free_shipping: 0,
          voucher_type: 'percentage',
          prefix: this.isVnSource ? prefixVn : (await Cookies.get('vending_machine_device')) == 'true' ? 'LAVM' : 'VM',
          email: this.$store.state.auth.userProfile ? this.$store.state.auth.userProfile.email : '',
          user_id: this.$store.state.auth.userProfile ? this.$store.state.auth.userProfile._id : '',
          points: this.zits,
          store_id: this.store_id,
          store_name: this.store_name,
          store_alias: this.store_alias,
          type_of_voucher: 'product_cost',
        };

        const headers = {
          Authorization: `Bearer ${tokenStr}`,
        };

        if (this.isVnSource) {
          headers['SOC-PLATFORM'] = 'sociolla-vn-pos';
          headers['source'] = 'store_kota';
        }

        this.$MS_SOCO_PUBLIC_API_URL
          .post(`customer/create/voucher`, payload, {
            headers: headers,
          })
          .then((response) => {
            this.processing = false;
            if (response.status == 200) {
              this.voucherCode = response.data.data.code;
              this.voucherEmail = response.data.data.rules.users ? response.data.data.rules.users[0].email : '';
              setTimeout(() => {
                JsBarcode('#barcode').init();
              }, 400);
              this.limitUser();
              this.startTimer();
            }
          })
          .catch((err) => {
            console.log(err);
            this.$toasted.global.error(this.isVnSource ? 'Vui lòng thử lại.' : 'Please try again later.');
            this.$router.push(this.isVnSource ? '/vending-machine-vn/home?isvn=true' : '/vending-machine/home');
          });
      }
    },
    startTimer() {
      this.interval = setInterval(() => {
        this.$router.push(this.isVnSource ? '/vending-machine-vn/home?isvn=true' : '/vending-machine/home');
        clearInterval(this.interval);
      }, this.timeout);
    },
  },
};
</script>

<style lang="scss" scoped>
.bbb-finish {
  min-height: 100vh;
  background: #000 url('/static/img/big-bang-boom/bg-bbb-5-store.png') no-repeat top center;
  background-size: 100% auto;
  padding: 150px 0 0;
  box-sizing: border-box;
}
.title {
  display: block;
  margin: 0 auto 146px;
}
.title-vn {
  margin: 0 auto 126px;
}
.score-vn {
  margin: 0 auto 40px !important;
  font-family: 'SVN-brandontext-bold', sans-serif !important;
}
.score {
  width: 400px;
  height: 400px;
  background: transparent url('/static/img/big-bang-boom/score-circle-store.svg') no-repeat center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font: normal 42px/42px 'brandontext-bold', sans-serif;
  color: #eb395f;
  margin: 0 auto 60px;
}
.score span {
  font: normal 90px/32px 'brandontext-bold', sans-serif;
  color: #fff;
  display: block;
  width: 100%;
  text-align: center;
  margin: 42px 0;
}
.bbb-pages {
  .box-congrats {
    background: transparent;
    height: auto;
    padding: 0;
  }
  .button-print-voucher {
    background: #d51d6e;
  }
  .box-congrats__heading {
    color: #eb395f;
  }
  .box-congrats__text {
    color: #fff;
  }
  .coupon {
    padding-top: 37px;
    background: transparent url('/static/img/big-bang-boom/voucher-bbb-store.png') no-repeat center;
    background-size: cover;
  }
  .coupon-vn {
    width: 638px;
    height: 215px;
    padding-top: 37px;
    background: transparent url('/static/img/big-bang-boom/coupon-bbb-vn.png') no-repeat center;
    background-size: cover;
    margin: 0 auto 61px auto;
    padding: 43px 73px 43px 128px;
  }
  .coupon__text-reward {
    color: #fff;
  }
}
.vn {
  font-family: 'SVN-brandontext-bold', sans-serif !important;
}

@media print {
  .title,
  .score {
    display: none;
  }
}
</style>
